<template>
  <common-card>
    <v-row>
      <v-col>
        <v-row style="padding: 15px 20px 0px 20px;">
          <v-col cols="12" md="6">
            <h1>Campañas Campañas</h1>
          </v-col>
          <v-col cols="12" md="6" style="text-align: end;">
            <v-btn
              class="common-botton"
              dark
              :loading="loading"
              @click="openModal(true)"
            >
              <v-icon class="mr-2">mdi-plus</v-icon>
              <span>Crear</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-expansion-panels
      elevation="0"
      style="padding: 30px; border-radius: 20px"
    >
      <v-expansion-panel>
        <v-expansion-panel-header style="padding: 35px;">
          <h3 style="color:#42352E;">
            Búsqueda detallada
          </h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="filtros.producto"
                label="Producto"
                outlined
                dense
                color="primary"
                background-color="white"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="filtros.medio"
                label="Medio"
                outlined
                dense
                color="primary"
                background-color="white"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                v-model="filtros.ramo"
                label="Ramo"
                :items="ramos"
                item-text="ramo"
                item-value="ramo"
                outlined
                dense
                color="primary"
                background-color="white"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                v-model="filtros.canal"
                label="Canal"
                :items="canales"
                item-text="canal"
                item-value="canal"
                outlined
                dense
                color="primary"
                background-color="white"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="filtros.servicio_embebido"
                label="Servicio embebido"
                outlined
                dense
                color="primary"
                background-color="white"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" style="text-align: end;">
              <v-btn
                color="primary"
                style="border-radius: 15px;  margin-right: 10px;"
                outlined
                dark
                :loading="loading"
                @click="clearFilters"
              >
                <span>Limpiar filtros</span>
              </v-btn>
              <v-btn
                class="common-botton"
                dark
                :loading="loading"
                @click="getCampanias(true)"
              >
                <span>Filtrar</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div style="padding: 0px 40px 0px 40px;">
      <hr />
    </div>
    <v-row>
      <v-col cols="12" class="mb-10" style="padding: 40px">
        <v-data-table
          :headers="capaingsTable"
          :items="campaings"
          item-key="name"
          class="elevation-0"
          :loading="loading"
          loading-text="Cargando..."
          no-data-text="Para ver elementos, primero realiza una búsqueda"
          :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50] }"
          :server-items-length="total"
          @update:page="page = $event"
          @update:items-per-page="size = $event"
        >
          <template v-slot:top>
            <v-row style="padding: 15px;">
              <v-col cols="8">
                <h2>Resultados de la búsqueda</h2>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.id="{ item }">
            <v-btn small icon @click="openModal(false, item.id)">
              <v-icon small>mdi-eye</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <CampaingsModal
      v-if="dialog"
      v-show="dialog"
      :dialog="dialog"
      :route="route"
      :idCampaing="idCampaing"
      @successCampaing="successModal"
      @closeModal="dialog = false"
    />
  </common-card>
</template>

<script>
import CommonCard from "@/components/commonComponents/CommonCard.vue";
import { getCampaingsList } from "@/services/campaings/campaing.service.js";
import { getCanalList } from "@/services/canal/canal.service.js";
import { getRamoList } from "@/services/ramo/ramo.service.js";

export default {
  components: {
    CommonCard,
    CampaingsModal: () =>
      import("@/components/commonComponents/dialogs/CampaingsModal.vue"),
  },
  data() {
    return {
      agentes: [],
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      roles: JSON.parse(localStorage.getItem("roles")),
      loading: false,
      search: "",
      capaingsTable: [
        {
          text: "Producto",
          align: "start",
          sortable: false,
          value: "producto",
        },
        { text: "Medio", value: "medio" },
        { text: "Ramo", value: "ramo" },
        { text: "canal", value: "canal" },
        { text: "Servicio embebedido", value: "servicio_embebido" },
        { text: "Ver", value: "id" },
      ],
      report: [],
      campaings: [],
      filtros: {
        producto: "",
        medio: "",
        servicio_embebido: "",
        ramo: "",
        canal: "",
      },
      ramos: [],
      canales: [],
      page: 0,
      size: 10,
      total: 0,
      dialog: false,
      idCampaing: 0,
      route:''
    };
  },

  computed: {},
  watch: {
    page(v) {
      if (v) this.pagina = v;
      this.getCampanias();
    },
    size(v) {
      if (v > 0) this.size = v;
      else this.size = this.total;

      this.getCampanias();
    },
  },
  methods: {
    async getCampanias(restartPagination = false) {
      this.loading = true;
      var finalFilters = {};
      Object.keys(this.filtros).map((e) => {
        if (this.filtros[e]) finalFilters[e] = this.filtros[e];
      });
      var body = {
        ...finalFilters,
        ...{
          page: !restartPagination ? this.page : 0,
          size: !restartPagination ? this.size : 10,
        },
      };
      var response = await getCampaingsList(body);
      this.campaings = response.campanias;
      this.total = response.numRegisters;
      this.loading = false;
    },
    async getRamos() {
      this.ramos = await getRamoList();
    },
    async getCanales() {
      this.canales = await getCanalList();
    },
    clearFilters() {
      Object.assign(this.filtros, {
        producto: "",
        medio: "",
        servicio_embebido: "",
        ramo: "",
        canal: "",
      });
      this.getCampanias(true);
    },
    openModal(isNew = false, idCampaing) {
      if (isNew) this.route = "v1/campanialead/insert";
      else {
        this.route = "v1/campanialead/update/" + idCampaing;
        this.idCampaing = idCampaing;
      }
      this.dialog = true
    },
    successModal(){
      this.dialog = false
      this.clearFilters();
    }
  },
  mounted() {
    this.getCampanias();
    this.getRamos();
    this.getCanales();
  },
};
</script>
<style scoped>
.theme--light.v-data-table {
  background-color: #f2fafc;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}

.v-expansion-panel {
  border-radius: 20px;
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 20px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #f6f5f9;
  color: rgba(0, 0, 0, 0.87);
}
</style>
